<ng-container>
  <div style="padding: 50px 20px; background: #bb7c7a">
    <ng-container *ngIf="langKey === 'de-DE'" ng-include="'app/website_fragments/products/webinar/webinar-de.html'">
      <app-webinar-de></app-webinar-de>
    </ng-container>
    <ng-container *ngIf="langKey === 'fr-CH'" ng-include="'app/website_fragments/products/webinar/webinar-fr.html'">
      <app-webinar-fr></app-webinar-fr>
    </ng-container>
  </div>
</ng-container>
